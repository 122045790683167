import * as React from 'react';

import styled from 'styled-components/macro';
import { colorsV2 } from 'style/colors-v2';
import { fromScreen } from 'utils/media-query/responsive.util';
import { LazyImage } from 'components/LazyImage';
import { Typography } from 'components/DesignSystemV2/Typography';
import ButtonText from 'components/ButtonText';
import { Link } from 'gatsby';

const Container = styled.div`
  display: grid;
  padding: 4px;
  border: 1px solid ${colorsV2.gray20};
  border-radius: 12px;
  .info {
    padding: 12px;
  }
  .content {
    display: grid;
    gap: 4px;
    margin-bottom: 24px;
    .bold {
      font-weight: 700;
    }
  }

  ${fromScreen(776)} {
    padding: 8px;
    grid-template-columns: 312px 1fr;
    .info {
      padding: 8px 8px 8px 16px;
    }
    .content {
      min-width: 360px;
    }
  }

  ${fromScreen(1144)} {
    padding: 12px;
    grid-template-columns: 432px 1fr;
    .content {
      gap: 16px;
      margin-bottom: 60px;
    }
    .info {
      padding: 20px 20px 20px 32px;
    }
  }
`;

const ImageContainer = styled.div`
  img {
    width: 100%;
    object-fit: cover;
  }
`;

const office = [
  {
    name: 'Cơ sở 1:',
    location: 'Hẻm 458/14, 3 Tháng 2, P12,Q. 10'
  },
  {
    name: 'Cơ sở 2:',
    location: '302 Nguyễn Văn Đậu, P11, Q.Bình Thạnh'
  },
  {
    name: 'Cơ sở 3:',
    location: 'Tòa nhà Sumikura, 18H Cộng Hòa, Q.Tân Bình'
  }
];

export const ContactOffline = () => {
  return (
    <Container>
      <div className="banner">
        <ImageContainer>
          <LazyImage
            className="thumbnail"
            handle="EFl5JYYTEGl5yJMgW7ww"
            alt="Cơ sở của DOL"
          />
        </ImageContainer>
      </div>
      <div className="info">
        <div className="content">
          <Typography variant="semi-bold/20-28">
            Hệ thống cơ sở của DOL tại TPHCM
          </Typography>

          {office.map(item => (
            <Typography
              variant="regular/14-20"
              key={item.name}
              color={colorsV2.black80}
            >
              <span className="bold">{item.name}</span> {item.location}
            </Typography>
          ))}
          <Typography variant="regular/14-20" color={colorsV2.black80}>
            Ngoài ra DOL còn có nhiều cơ sở ở các Quận khác trên toàn TPHCM, sắp
            tới sẽ khai trương ở Đà Nẵng và Hà Nội. Hãy cùng DOL chờ đón nhé!
          </Typography>
        </div>
        <Link to="/he-thong-co-so">
          <ButtonText content="Xem hệ thống cơ sở" type="primary" />
        </Link>
      </div>
    </Container>
  );
};
