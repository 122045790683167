import * as React from 'react';
import styled from 'styled-components';

import { fromScreen } from '../../utils/media-query/responsive.util';
import { Typography } from 'components/DesignSystemV2/Typography';

const Container = styled.section`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  row-gap: 24px;

  ${fromScreen(776)} {
    row-gap: 40px;
  }
`;

export const ContactSection = props => {
  const { title, content } = props;

  return (
    <Container>
      <Typography
        className="section-heading"
        variant="bold/24-32"
        tabletVariant="bold/32-40"
        desktopVariant="bold/40-48"
        v3
      >
        {title}
      </Typography>
      {content}
    </Container>
  );
};
