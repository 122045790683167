import * as React from 'react';
import { FixedTopBarScholarshipScheduleNOffline } from 'shared/SchedulePage/FixedTopBarScholarship/FixedTopBarScholarshipScheduleNOffline';
import MainLayout from '../components/Layout/MainLayout';
import HeaderSchedulePage from '../shared/Header/HeaderSchedulePage';
import PageBanner from 'shared/Contact/Header';
import { ContactOffline } from 'shared/Contact/ContactOffline';
import { ContactSection } from 'shared/Contact/ContactSection';
import MySEO from 'shared/MySEO';
import { contactPage } from 'shared/MySEO/constant';
import { BranchLocation } from 'shared/Center/BranchLocation';
import styled from 'styled-components';
import { fromScreen } from 'utils/media-query/responsive.util';
import { withHomePageSection } from 'components/withHomePageSection';

const Space = styled.div`
  > section {
    &:first-child {
      margin-top: 32px;
    }
    &:last-child {
      margin: 64px 0 32px 0;
    }

    ${fromScreen(776)} {
      &:first-child {
        margin-top: 100px;
      }
      &:last-child {
        margin: 100px 0;
      }
    }
  }
`;

const SectionContent = withHomePageSection(() => {
  return (
    <Space>
      <BranchLocation
        title="Các cơ sở của DOL"
        desc="Để đem Linearthinking đến gần các bạn hơn, hiện tại DOL có các cơ sở
        sau:"
      />
      <ContactSection title="Liên hệ offline" content={<ContactOffline />} />
    </Space>
  );
});

const Page = ({ location }) => {
  return (
    <MainLayout
      noHeader
      hideSideCTA
      renderCustomHeader={() => <HeaderSchedulePage />}
      showFooterRegister
    >
      <MySEO
        slug={location.pathname}
        h1="Thông tin liên hệ"
        title="Thông tin liên hệ của DOL IELTS Đình Lực"
        description="Xem thông tin liên hệ, đăng ký luyện thi IELTS bằng phương pháp tư duy tuyến tính độc quyền ở Việt Nam tại Trung tâm IELTS Đình Lực - DOL English"
      >
        <script type="application/ld+json">
          {JSON.stringify(contactPage)}
        </script>
      </MySEO>
      <FixedTopBarScholarshipScheduleNOffline />
      <PageBanner
        title={`Thông tin <span class="highlight">liên hệ</span><br/>`}
        description="Để được hỗ trợ làm Test đầu vào miễn phí hoặc Tư vấn Khóa học IELTS tại DOL, bạn có thể liên hệ trực tiếp với DOL IELTS Đình Lực qua Hotline, Email, Inbox hoặc đến trực tiếp các cơ sở của DOL"
      />
      <SectionContent />
    </MainLayout>
  );
};

export default Page;
